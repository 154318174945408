import { dataPartSchema, postListItemSchema } from "@hsl/fund-page/schemas";
import { z } from "zod";
import { ButtonWithArrow } from "../../../components/Button";
import { ListContainer as CardListContainer } from "../../../components/Card/Card";
import FundPageContent from "../../../components/FundPageContent";
import GreyCurveContainer from "../../../components/GreyCurveContainer";
import NewsAndViewsCard from "../../../components/NewsAndViewsCard";
import { Insight } from "../../../hooks/useInsights";
import usePageSection from "../../../hooks/usePageSection";
import useRelatedFunds from "../../../hooks/useRelatedFunds";

const newsAndViewsSchema = dataPartSchema.extend({
    data: z.array(postListItemSchema),
});
export const SingleEventReadNewsAndViews = () => {
    const { data, title } = usePageSection("newsAndViews", newsAndViewsSchema);

    const relatedFundIds = useRelatedFunds();

    const postsFilteredByFund = data.filter((post) => {
        const postFundIds = post.funds.map((x) => x.id);
        return relatedFundIds.some((x) => x && postFundIds.includes(x));
    });

    if (postsFilteredByFund.length === 0) {
        return null;
    }

    const postToInsight = (
        post: z.infer<typeof newsAndViewsSchema>["data"][0]
    ): Insight => {
        const [fragmentA, fragmentB] = post.image.split("/static/");
        const image = `${fragmentA ? fragmentA : fragmentB}`;
        return {
            asAtDate: post.date,
            id: parseInt(`${post.id}`),
            image: image,
            publicationImage: undefined,
            readUrl: post.read_url,
            title: post.title,
            type: post.type,
            category: {
                name: post.category_name,
                colour: post.category_colour,
            },
            authors: [
                {
                    id: 0,
                    name: post.author_name,
                    primaryHeadshot: "",
                },
            ],
            funds: post.funds.map((f) => ({
                id: parseInt(`${f.id}`),
                name: f.name,
            })),
            synopsis: post.synopsis,
            comprehensiveSynopsis: "",
            createdDate: post.date,
            customTags: post.custom_tags.map((ct) => ({
                id: parseInt(`${ct.id}`),
                name: ct.name,
            })),
            featuredStates: [],
            fundGroups: [],
            fundTypes: [],
            updatedDate: post.date,
            validFromDate: post.date,
            body: post.post_body,
        };
    };

    return (
        <>
            <GreyCurveContainer>
                <CardListContainer>
                    <div>
                        <FundPageContent.Header h2 className="font-extralight">
                            {title}
                        </FundPageContent.Header>
                        <CardListContainer>
                            {postsFilteredByFund.slice(0, 3).map((post) => {
                                const insight = postToInsight(post);
                                return (
                                    <NewsAndViewsCard
                                        key={post.id}
                                        {...insight}
                                    />
                                );
                            })}
                        </CardListContainer>
                    </div>
                </CardListContainer>
                <ButtonWithArrow className="mt-6" href="/News-Views">
                    More news and views
                </ButtonWithArrow>
            </GreyCurveContainer>
        </>
    );
};
